.loultimo-parent {
  display: flex;
  font-family: "Roboto", sans-serif;
  flex-direction: column;
  letter-spacing: 0.5px;
  margin-bottom: 0;
  margin-top: 0;
}

.loultimo-title {
	font-family: "Roboto", sans-serif !important;
	font-size: 1.55rem;
	font-weight: 600 !important;
	color: var(--sky-blue);
	text-transform: uppercase;
	margin-top: 0;
}

.loultimo-container {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
}

.loultimo-container-text {
  font-weight: 400;
  line-height: 1.36rem;
  padding: .7rem;
  padding-left: 0;
  border-bottom: 1px solid var(--shadow-gray);
}

.loultimo-container-text:nth-last-child(1) {
  border-right: 1px solid transparent;
  padding-right: 0;
}

.loultimo-container-text a {
  text-decoration: none;
}

.loultimo-hour {
  color: var(--sky-blue);
  font-weight: 700;
}

@media (max-width: 980px) {
  .loultimo-container {
    font-size: 1rem;
    grid-template-columns: repeat(1, 1fr);
    line-height: 22px;
  }
  .loultimo-container-text {
    margin-bottom: 0rem;
    font-size: 1.1rem;
    line-height: 1.5rem;
    font-weight: 400;
  }
}

@media (max-width: 650px) {
  .loultimo-parent {
    max-width: 100%;
  }
}